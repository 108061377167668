import React from 'react'
import Introduction from '../Components/Introduction'
import Works from '../Components/Works'
import Service from '../Components/Service'
import Review from '../Components/Review'
import Offer from '../Components/Offer'
import IntroCall from '../Components/IntroCall'
import Question from '../Components/Question'
import Right from '../Components/Right'
import BigHeading from '../Components/BigHeading'
import Media from '../Assests/Media'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>TechJoy | 50% Off Web, App & Software Development for New Clients</title>
        <meta name="description" content="TechJoy: Expert web, app, and custom software development. Enjoy 50% off for first-time customers. Based in Bhiwandi, India, serving clients globally." />
        <meta name="keywords" content="techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions" />
        <link rel="canonical" href="https://techjoy.in" />
        <meta property="og:title" content="TechJoy | 50% Off Web, App & Software Development for New Clients" />
        <meta property="og:description" content="Get 50% off on expert web, app, and custom software development services for first-time customers. Transform your business with TechJoy's innovative, affordable solutions." />
        <meta property="og:title" content="TechJoy | 50% Off Web, App & Software Development for New Clients" />
        <meta property="og:description" content="Get 50% off on expert web, app, and custom software development services for first-time customers. Transform your business with TechJoy's innovative, affordable solutions." />
        <meta property="og:url" content="https://techjoy.in" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://techjoy.in/public/favicon.ico" />
        <meta property="og:site_name" content="TechJoy" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:see_also" content="https://www.facebook.com/Techjoy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="TechJoy | 50% Off Web, App & Software Development" />
        <meta name="twitter:description" content="Get 50% off on expert web, app, and custom software development services for first-time customers. Transform your business with TechJoy's innovative, affordable solutions." />
        <meta name="twitter:image" content="https://techjoy.in/public/favicon.ico" />
        {/* language and thier regions  */}
        <link rel="alternate" href="https://techjoy.in" hreflang="en-us" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-gb" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-in" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-au" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ca" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-nz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-sg" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-za" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ie" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ph" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-hk" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-mt" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ke" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-nl" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ug" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-nz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-gh" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-tz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ae" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-jp" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-eg" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-dz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-om" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-qa" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-sa" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-jo" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ma" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ye" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-tn" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-bd" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-lk" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-mu" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-pk" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-my" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-id" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-vn" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-th" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-kr" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-cn" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-tw" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ph" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-az" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-kz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-uz" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-tm" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-kg" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ge" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-am" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-tr" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ir" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-il" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-ps" />
        <link rel="alternate" href="https://techjoy.in" hreflang="en-jo" />
        <script type="application/ld+json">
          {`
       {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "TechJoy",
  "url": "https://techjoy.in",
  "logo": "https://techjoy.in/public/favicon.ico",
  "sameAs": [
    "https://www.instagram.com/TechJoy.in_/"
  ],
  "description": "TechJoy offers expert web, app, and custom software development services with an incredible 50% discount for first-time customers.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Aadeswar Tower B wing Anjurphata road Bhiwandi Thane ",
    "addressLocality": "Bhiwandi",
    "addressRegion": "MH",
    "postalCode": "400001",
    "addressCountry": "IN"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+91 9860852466",
    "contactType": "Customer Service"
  },
  "offers": {
    "@type": "Offer",
      "description": "50% off for first-time customers on all services",
            "discount": "50%",
    "url": "https://techjoy.in",
    "priceCurrency": "INR",
    "price": "0",  // Replace with actual price if applicable
    "validFrom": "2024-01-01",
    "validThrough": "2024-12-31",
    "eligibleRegion": {
      "@type": "Place",
      "name": "India"
    },
    "description": "50% off for first-time customers on all services",
    "availability": "https://schema.org/InStock",
    "seller": {
      "@type": "Organization",
      "name": "TechJoy"
    }
  }
}

      `}
        </script>
      </Helmet>
      {/* <Loader /> */}
      <div className='flex flex-col justify-center items-center w-full h-fit mb-10'>
        <h6 className='company_name mt-5 mb-5 flex flex-row gap-1 justify-center items-center'> <img className='w-6 h-fit' src={Media.smile} alt="Techjoy webiste software and app development agency" /> <Link>Techjoy</Link></h6>
        <div id="background_image" className='w-11/12 h-fit overflow-hidden'>
          {/* <Introduction /> */}
          <Works />
          <Service />
          <Review />
          <BigHeading heading="upto 50% of discount for first time customer" />
          <Offer />
          <IntroCall />
          <Question />
          <Right />
          <BigHeading heading="TechJoy" image={Media.smile} />
          <Footer />
        </div>
      </div>
    </>

  )
}

export default Home
