import React from 'react';
import { Helmet } from 'react-helmet';

const LearnMore = () => {
    return (
        <>
            <Helmet>
                <title>Learn More About TechJoy Services | 50% Off for New Clients</title>
                <meta name="description" content="Discover TechJoy's comprehensive web, app, and custom software development services. Get answers to your questions and enjoy an 50% discount on your first project. Expert solutions tailored to your needs." />
                <meta name="keywords" content="TechJoy services, web development FAQ, app development information, custom software details, 50% discount, new client offer" />
                <link rel="canonical" href="https://techjoy.in/learn-more" />
                <meta property="og:title" content="Learn More About TechJoy Services | 50% Off for New Clients" />
                <meta property="og:description" content="Explore TechJoy's web, app, and custom software development services. Get your questions answered and enjoy an 50% discount on your first project." />
                <meta property="og:url" content="https://techjoy.in/learnmore" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://techjoy.in/public/favicon.ico" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Learn More About TechJoy Services | 50% Off for New Clients" />
                <meta name="twitter:description" content="Explore TechJoy's web, app, and custom software development services. Get your questions answered and enjoy an 50% discount on your first project." />
                <meta name="twitter:image" content="https://techjoy.in/public/favicon.ico" />
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What services does TechJoy offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "TechJoy offers web development, app development, and custom software development services."
              }
            },
            {
              "@type": "Question",
              "name": "Is there a discount for new clients?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, TechJoy offers an incredible 50% discount for first-time customers on all services."
              }
            }
          ]
        }
      `}
                </script>
            </Helmet>
            <div className="container mx-auto p-6">
                <h1 className="text-3xl font-bold mb-4">Package Details</h1>

                {/* Website – Standard Plan */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-3">Website – Standard Plan</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Dynamic Website:</strong> A dynamic website that can update and display different content based on user interactions or other factors.</li>
                        <li><strong>Responsive:</strong> A responsive design ensures your website looks great and functions well on any device, from smartphones to desktops.</li>
                        <li><strong>Complete SEO:</strong> Comprehensive SEO services to optimize your website for higher search engine rankings, driving more organic traffic.</li>
                        <li><strong>Detailed Code Comment:</strong> Thorough code commenting to make the website's code easier to understand and maintain for future developers.</li>
                        <li><strong>Contact / Appointment Form:</strong> Functional contact or appointment forms to facilitate communication and appointment scheduling.</li>
                        <li><strong>Custom Made Design:</strong> Unique, custom-made design tailored to your brand's identity and needs.</li>
                        <li><strong>6 Months of Maintenance:</strong> Six months of maintenance to keep your website running smoothly, including updates, troubleshooting, and minor changes.</li>
                        <li><strong>Brand Feel:</strong> Design elements that reflect and reinforce your brand’s identity, creating a professional and cohesive appearance.</li>
                        <li><strong>Average 1 Week Delivery:</strong> Quick turnaround with an average delivery time of one week, depending on project complexity.</li>
                        <li><strong>One-on-One Calls and Meetings:</strong> Personalized communication through calls and meetings to discuss requirements, provide feedback, and stay updated on progress.</li>
                        <li><strong>Physical Visit:</strong> In-person meetings if necessary to discuss project details and ensure thorough collaboration.</li>
                        <li><strong>Up to 7 Pages (Additional Charges for More):</strong> Creation of up to 7 standard pages, with additional pages available for an extra charge.</li>
                    </ul>
                    <p className="mt-4"><strong>Not included:</strong> Domain, Backend, Database Connection and Storage.</p>
                </section>

                {/* Website – Premium Plan */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-3">Website – Premium Plan</h2>
                    <p>All perks of the Standard package plus:</p>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Backend:</strong> Comprehensive backend development to handle server-side operations and processing, ensuring robust functionality and performance.</li>
                        <li><strong>Database Connection and Storage:</strong> Setup and maintenance of databases to store and manage your data efficiently, enabling dynamic content and user-specific features.</li>
                        <li><strong>Multiple Users:</strong> Support for multiple user accounts, allowing different levels of access and permissions for administrators, editors, and regular users.</li>
                        <li><strong>Posting / Purchasing:</strong> Functionality for users to post content or make purchases directly on your website, enabling interactive and e-commerce features.</li>
                        <li><strong>Payment Gateway:</strong> Integration of secure payment gateways to facilitate online transactions, supporting various payment methods for user convenience.</li>
                        <li><strong>Dashboard:</strong> A user-friendly dashboard for managing website content, user accounts, and other administrative tasks, providing easy control over your site.</li>
                        <li><strong>Ecommerce / Hotel Booking / etc.:</strong> Advanced features to support specific business models like e-commerce stores, hotel booking systems, and more, tailored to your industry needs.</li>
                        <li><strong>Data Entry, Sharing, and Manipulation:</strong> Capabilities for data entry, sharing, and manipulation, allowing users to input, share, and modify data as needed.</li>
                        <li><strong>SaaS (Software as a Service):</strong> Development of SaaS applications, enabling you to offer software solutions as a service to your customers, with cloud-based access and management.</li>
                        <li><strong>Number of Pages According to Requirements:</strong> The number of pages can be customized based on your specific needs, providing flexibility to create a website that perfectly fits your content and business goals.</li>
                    </ul>
                    <p className="mt-4"><strong>Not included:</strong> Hosting and Domain.</p>
                </section>

                {/* App Development Package */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-3">App Development Package</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Cross-Platform Compatibility:</strong> Development of apps that work seamlessly on both iOS and Android platforms, ensuring a wide reach and consistent user experience.</li>
                        <li><strong>User Authentication:</strong> Implementation of secure user authentication methods, including email, social media logins, and multi-factor authentication to ensure user data security.</li>
                        <li><strong>Push Notifications:</strong> Setup of push notifications to keep users engaged with real-time updates, reminders, and alerts directly on their devices.</li>
                        <li><strong>In-App Purchases:</strong> Integration of in-app purchase options, enabling users to buy additional content, features, or subscriptions directly within the app.</li>
                        <li><strong>Real-Time Chat:</strong> Development of real-time chat functionality, allowing users to communicate instantly within the app.</li>
                        <li><strong>Geo-Location Services:</strong> Incorporation of geo-location services to provide location-based features, such as maps, navigation, and location-specific content.</li>
                        <li><strong>Social Media Integration:</strong> Seamless integration with popular social media platforms for easy sharing, login, and enhanced user engagement.</li>
                        <li><strong>Custom UI/UX Design:</strong> Design of a unique and intuitive user interface (UI) and user experience (UX) tailored to your brand and user needs, ensuring a visually appealing and easy-to-use app.</li>
                        <li><strong>API Integration:</strong> Integration of various APIs (Application Programming Interfaces) to enhance app functionality, such as payment gateways, third-party services, and more.</li>
                        <li><strong>Performance Optimization:</strong> Optimization of the app's performance to ensure fast load times, smooth navigation, and efficient resource usage.</li>
                        <li><strong>App Analytics:</strong> Implementation of app analytics to track user behavior, app performance, and other key metrics, providing valuable insights for improvement.</li>
                        <li><strong>Regular Updates:</strong> Provision of regular updates to keep the app current with new features, improvements, and compatibility with the latest OS versions.</li>
                        <li><strong>Bug Fixes:</strong> Continuous monitoring and fixing of bugs to ensure a smooth and error-free user experience.</li>
                        <li><strong>3 Months of Maintenance:</strong> Three months of post-launch maintenance, including updates, bug fixes, and minor changes to ensure the app remains functional and up-to-date.</li>
                    </ul>
                    <p className="mt-4"><strong>Not included:</strong> Uploading Cost.</p>
                </section>

                {/* Software Development Package */}
                <section>
                    <h2 className="text-2xl font-semibold mb-3">Software Development Package</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Scalable Solutions:</strong> Our solutions are designed to grow with your business. Whether you start with a small user base or expect rapid expansion, our scalable architecture ensures your software can handle increased demand without compromising performance.</li>
                        <li><strong>Custom Features:</strong> We develop custom features tailored to your specific business needs, ensuring the software does exactly what you require. From unique workflows to specialized tools, we build functionality that supports your operations seamlessly.</li>
                        <li><strong>Database Management:</strong> Efficient database management is critical for storing, retrieving, and handling your data. We design and maintain robust database systems to ensure your data is secure, accessible, and well-organized.</li>
                        <li><strong>User Roles and Permissions:</strong> Implementing user roles and permissions allows for controlled access to various parts of the software. This ensures that users have appropriate access levels based on their responsibilities, enhancing security and operational efficiency.</li>
                        <li><strong>Integration with Existing Systems:</strong> Our solutions can be seamlessly integrated with your existing systems, ensuring smooth data flow and operational continuity. This includes CRM, ERP, and other business tools you already use.</li>
                        <li><strong>Automation:</strong> We incorporate automation to streamline repetitive tasks, reduce errors, and increase efficiency. This can include automated data entry, process workflows, notifications, and more.</li>
                        <li><strong>Cloud-Based Solutions:</strong> Our cloud-based solutions offer flexibility, scalability, and accessibility from anywhere with an internet connection. This approach also provides enhanced data security and disaster recovery options.</li>
                        <li><strong>Data Analytics:</strong> We provide data analytics capabilities to help you make informed decisions. This includes tools for data visualization, reporting, and predictive analytics to gain insights from your data.</li>
                        <li><strong>Security Protocols:</strong> Ensuring the security of your software is paramount. We implement robust security protocols, including encryption, secure user authentication, and regular security audits to protect your data.</li>
                        <li><strong>User Training:</strong> We offer comprehensive user training to ensure your team can effectively use the new software. This includes training sessions, user guides, and ongoing support to address any questions or issues.</li>
                        <li><strong>Detailed Documentation:</strong> Our detailed documentation provides clear instructions and information about the software’s functionality, setup, and usage. This helps in troubleshooting, training new users, and understanding the system.</li>
                        <li><strong>Cross-Platform Accessibility:</strong> We design software that is accessible across various platforms, including desktop, mobile, and web. This ensures that users can access and use the software regardless of the device they are using.</li>
                        <li><strong>Custom API Development:</strong> We develop custom APIs to allow your software to communicate with other systems and applications. This enables integration, data exchange, and extending the functionality of your software.</li>
                        <li><strong>Reporting Tools:</strong> Our software includes powerful reporting tools to generate detailed reports and insights. Customize reports to track key performance indicators, monitor operations, and support decision-making.</li>
                        <li><strong>Ongoing Support:</strong> We provide ongoing support to ensure your software continues to meet your needs. This includes technical support, updates, and addressing any issues that arise after deployment.</li>
                        <li><strong>6 Months of Free Maintenance:</strong> Enjoy six months of free maintenance after deployment. This includes updates, bug fixes, and minor improvements to ensure your software remains functional and up-to-date.</li>
                    </ul>
                    <p className="mt-4"><strong>Note:</strong> No upload cost if the software is to be used privately. Otherwise, the uploading cost will be added to the total estimation, depending on the platform you want your software to be uploaded.</p>
                </section>
                <section>
                    <h2 className="text-2xl font-semibold mb-3">Disclaimer</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li><strong>Note:</strong> 50% advance payment <br />
                            The day after the payment will be considered as day 1 of the project <br />
                            Domain cost depends on the which domain you want ,domain cost will be added </li>
                    </ul>
                </section>
            </div>
        </>

    );
}

export default LearnMore;
