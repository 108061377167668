import React from 'react';

const BigHeading = (props) => {
    const display = props.image ? (
        <div data-aos="fade-up" className='flex flex-row justify-center items-center w-full h-fit'>
            <img className='md:w-1/4 w-1/5' src={props.image} alt="Sure, here are some keywords related to a tech agency like TechJoy that provides online services globally but is based in Maharashtra:

techjoy tech agency web development app development custom software Bhiwandi Maharashtra global tech services digital transformation innovative tech solutions online presence enhancement" />
            <h2 className='company_name big_heading tracking-tighter'>{props.heading}</h2>
        </div>
    ) : (
        <div className='flex flex-row justify-center items-center w-full h-fit mt-[150px]'>
            <h2 className='company_name big_heading tracking-tighter'>{props.heading}</h2>
        </div>
    );

    return display;
};

export default BigHeading;