import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

export const Meet = () => {



    const [formData, setFormData] = useState({
        package_name: '',
        user_name: '',
        user_email: '',
        user_phone: '',
        user_address: '',
        user_date: '',
        user_time: '',
        project_description: '',
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_yp2clzl', 'template_0fjuvud', e.target, 'vMMuU8KueyoZ85f3r')
            .then(
                () => {
                    alert("Message Sent Succesfully");
                    alert("You will get reply within an hour");
                    console.log('SUCCESS!');
                    // Reset form data after successful submission
                    setFormData({
                        package_name: '',
                        user_name: '',
                        user_email: '',
                        user_phone: '',
                        user_address: '',
                        user_date: '',
                        user_time: '',
                        project_description: '',
                    });
                },
                (error) => {
                    alert("Failed to send message");
                    console.log('FAILED...', error);
                },
            );
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Helmet>
                <title>Schedule a Meeting with TechJoy | Discuss Your Project & Get 50% Off</title>
                <meta name="description" content="Book an online meeting with TechJoy to discuss your web, app, or custom software project. New clients receive an 50% discount on their first project. Let's transform your ideas into reality." />
                <meta name="keywords" content="TechJoy meeting, online consultation, web development, app development, custom software, 50% discount, new client offer" />
                <link rel="canonical" href="https://techjoy.in/meet" />
                <meta property="og:title" content="Schedule a Meeting with TechJoy | Discuss Your Project & Get 50% Off" />
                <meta property="og:description" content="Book an online meeting with TechJoy. Discuss your tech project and get 50% off as a new client. Let's bring your ideas to life." />
                <meta property="og:url" content="https://techjoy.in/meet" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://techjoy.in/public/favicon.ico" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Schedule a Meeting with TechJoy | Discuss Your Project & Get 50% Off" />
                <meta name="twitter:description" content="Book an online meeting with TechJoy. Discuss your tech project and get 50% off as a new client. Let's bring your ideas to life." />
                <meta name="twitter:image" content="https://techjoy.in/public/favicon.ico" />
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "Service",
          "name": "TechJoy Online Consultation",
          "description": "Book an online meeting with TechJoy to discuss your web, app, or custom software project. New clients receive an 50% discount on their first project.",
          "provider": {
            "@type": "Organization",
            "name": "TechJoy",
            "url": "https://techjoy.in"
          },
          "offers": {
            "@type": "Offer",
            "description": "50% off for first-time customers on all services",
            "discount": "50%"
          }
        }
      `}
                </script>
            </Helmet>
            <div className="bg-white py-12">
                <div id='background_image' className="box_shadow max-w-md mx-auto bg-white rounded-lg shadow-md p-8">
                    <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
                    <form onSubmit={sendEmail} className="space-y-4">

                        <div>
                            <label htmlFor="user_name" className="block font-medium">
                                User Name:
                            </label>
                            <input
                                type="text"
                                required
                                name="user_name"
                                value={formData.user_name}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="user_email" className="block font-medium">
                                User Email:
                            </label>
                            <input
                                type="email"
                                required
                                name="user_email"
                                value={formData.user_email}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="user_phone" className="block font-medium">
                                User Phone:
                            </label>
                            <input
                                type="text"
                                required
                                name="user_phone"
                                value={formData.user_phone}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="user_address" className="block font-medium">
                                User Address:
                            </label>
                            <input
                                type="text"
                                name="user_address"
                                value={formData.user_address}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="user_date" className="block font-medium">
                                User Date:
                            </label>
                            <input
                                type="date"
                                required
                                name="user_date"
                                value={formData.user_date}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="user_time" className="block font-medium">
                                User Time:
                            </label>
                            <input
                                type="time"
                                required
                                name="user_time"
                                value={formData.user_time}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="project_description" className="block font-medium">
                                Project Description:
                            </label>
                            <textarea
                                name="project_description"
                                required
                                value={formData.project_description}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="3"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white rounded-md py-2 hover:bg-indigo-700 transition-colors duration-300"
                        >
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </>

    );
};
