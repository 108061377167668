import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Offer = () => {


    const [selectedService, setSelectedService] = useState('website');
    const [selectedPlan, setSelectedPlan] = useState('standard');

    const navigate = useNavigate();

    const handleNavigate = () => {
        if (selectedService === 'website') {
            navigate('/contact', { state: selectedPlan });
        }
        else {
            navigate('/contact', { state: selectedService });
        }
    }

    const handleInfo = () => {
        navigate('/learnmore')
    }
    // offers 
    const offers = {
        website: {
            standard: {
                packageName: "Standard Website Plan",
                included: [
                    'Dynamic Website',
                    'Responsive',
                    'Complete SEO',
                    'Detail Code Comments',
                    'Contact / Appointment Form',
                    'Custom Made Design',
                    '6 Months of Maintenance',
                    'Brand Feel',
                    'Average 1 Week Delivery',
                    'One on One Calls and Meetings',
                    'Physical Visit',
                    'Up to 7 Pages (for more charges may change)'
                ],
                exlucded: [
                    'Domain',
                    'Hosting subscription',
                    'Backend',
                    'Database connectivity'
                ],
                realRate: "50% Discount",
                dummyRate: "on services",
            },
            premium: {
                packageName: "Premium Website Plan",
                included: [
                    'Standard package + ',
                    'Backend',
                    'Database connection and storage',
                    'Multiple users',
                    'Posting / purchasing',
                    'Payment gateway',
                    'Dashboard',
                    'Ecommerce / hotel booking / etc',
                    'Data entry, sharing and manipulation',
                    'SaaS',
                    'Pages according to the requirements'
                ],
                exlucded: [
                    'Domain',
                    'Hosting subscription',
                ],
                realRate: "50% Discount",
                dummyRate: "on services",
            },
        },
        software: {
            packageName: "Custom Software Service",
            included: [
                'Scalable Solutions',
                'Custom Features',
                'Database Management',
                'User Roles and Permissions',
                'Integration with Existing Systems',
                'Automation',
                'Cloud-Based Solutions',
                'Data Analytics',
                'Security Protocols',
                'User Training',
                'Detailed Documentation',
                'Cross-Platform Accessibility',
                'Custom API Development',
                'Reporting Tools',
                'Ongoing Support',
                '6 Months of Free Maintenance'
            ],
            realRate: "50% Discount",
            dummyRate: "on services",
        },
        app: {
            packageName: "App Development Service",
            included: [
                'Cross-Platform Compatibility',
                'User Authentication',
                'Push Notifications',
                'In-App Purchases',
                'Real-Time Chat',
                'Geo-Location Services',
                'Social Media Integration',
                'Custom UI/UX Design',
                'API Integration',
                'Performance Optimization',
                'App Analytics',
                'Regular Updates',
                'Bug Fixes',
                'App Store Submission',
                '3 Months of Maintenance'
            ],
            exlucded: [
                "Uploading Cost",
            ],
            realRate: "50% Discount",
            dummyRate: "on services",
        }
    }

    const renderPlanDetails = () => {
        let currentOffer;
        if (selectedService === 'website') {
            currentOffer = offers.website[selectedPlan];
        } else {
            currentOffer = offers[selectedService];
        }

        return (
            <>
                {/* <h2 className="ml-6 text-3xl font-semibold">{currentOffer.packageName}</h2> */}
                <p className='mt-[5px] ml-6 text-5xl font-sans font-semibold'>{currentOffer.realRate} <span className='text-2xl font-sans font-semibold'>{currentOffer.dummyRate}</span></p>
                {/* balck line  */}
                <div className="ml-6 mb-6 w-5/6 h-1  bg-black"></div>

                <p className='ml-6 text-xl font-semibold'>** What's included ** <span onClick={handleInfo} className='text-xs text-blue-700 cursor-pointer'>learn more</span></p>
                <ul className='ml-12 list-disc'>
                    {currentOffer.included.map((item, index) => (
                        <li key={index} className='text-2xl font-sans font-semibold'>{item}</li>
                    ))}
                </ul>

                {currentOffer.exlucded && (
                    <>
                        <p className='ml-6 text-xl font-semibold'>** What's not included ** <span onClick={handleInfo} className='text-xs text-blue-700 cursor-pointer'>learn more</span></p>
                        <ul className='ml-12 list-disc'>
                            {currentOffer.exlucded.map((item, index) => (
                                <li key={index} className='text-2xl font-sans font-semibold'>{item}</li>
                            ))}
                        </ul>
                    </>
                )}
            </>
        );
    };

    return (
        <div id='pricing' className='box_shadow border_2px_solid_black w-10/12 h-fit flex flex-col justify-center items-start rounded-2xl m-auto mt-[150px] gap-2'>
            <h2 className='text-4xl font-sans font-semibold text-nowrap mt-[31px] ml-6 mb-6'>Can't-Miss Deal</h2>

            {/* 3 buttons code  */}
            <div className="flex flex-row justify-center items-center w-11/12 md:w-3/5 h-11 bg-black rounded-full ml-2 md:ml-6">
                <button onClick={() => setSelectedService('website')} className={`button_foucs border_2px_solid_black w-1/3 h-11 flex justify-center items-center rounded-full text-white text-lg md:text-xl font-sans font-semibold ${selectedService === 'website' ? 'bg-yellow-500 text-black' : ''}`}>Website</button>
                <button onClick={() => setSelectedService('software')} className={`button_foucs border_2px_solid_black w-1/3 h-11 flex justify-center items-center rounded-full text-white text-lg md:text-xl font-sans font-semibold ${selectedService === 'software' ? 'bg-white text-black' : ''}`}>Software</button>
                <button onClick={() => setSelectedService('app')} className={`button_foucs border_2px_solid_black w-1/3 h-11 flex justify-center items-center rounded-full text-white text-lg md:text-xl font-sans font-semibold ${selectedService === 'app' ? 'bg-white text-black' : ''}`}>App</button>
            </div>

            {/* second 2 buttons  */}
            {selectedService === 'website' && (
                <div className="flex flex-row justify-center items-center w-3/4 md:w-1/2 h-11 bg-black rounded-full ml-2 md:ml-6">
                    <button onClick={() => setSelectedPlan('standard')} className={`button_foucs border_2px_solid_black w-1/2 h-11 flex justify-center items-center rounded-full text-white text-lg md:text-xl font-sans font-semibold ${selectedPlan === 'standard' ? 'bg-yellow-500 text-black' : ''}`}>Standard</button>
                    <button onClick={() => setSelectedPlan('premium')} className={`button_foucs border_2px_solid_black w-1/2 h-11 flex justify-center items-center rounded-full text-white text-lg md:text-xl font-sans font-semibold ${selectedPlan === 'premium' ? 'bg-yellow-500 text-black' : ''}`}>Premium</button>
                </div>
            )}

            {/* discount line  */}
            {/* <p className='text-xl font-medium tracking-normal text-wrap md:text-nowrap w-full text-black ml-2 md:ml-6'>15% off on services for first time customer <span className='text-xs text-blue-700 cursor-pointer'>learn more</span> </p> */}

            {/* Dynamic content */}
            {renderPlanDetails()}



            {/* disclaimer  */}
            <ol className='ml-12 list-disc'>
                <li className='text-blue-700 text-xl font-sans font-semibold'>50% advance payment</li>
                <li className='text-blue-700 text-xl font-sans font-semibold'>The day after the advance payment will be considered as day 1 of the project</li>
                <li className='text-blue-700 text-xl font-sans font-semibold'>Domain cost depends on the which domain you want , domain cost will be added respectively</li>
            </ol>

            {/* Get started button  */}
            {/* <button onClick={handleNavigate} className='ml-6 mb-5 w-28 h-11 bg-slate-800 rounded-xl text-white  font-sans font-medium text-xs md:w-48 md:h-14 md:text-xl'>Get started</button> */}
            <button onClick={handleNavigate} className="animated-button ml-6 mb-6">
                <svg xmlns="http://www.w3.org/2000/svg" class="arr-2" viewBox="0 0 24 24">
                    <path
                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                    ></path>
                </svg>
                <span className="text">Get Started</span>
                <span className="circle"></span>
                <svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
                    <path
                        d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                    ></path>
                </svg>
            </button>

        </div>
    )
}

export default Offer