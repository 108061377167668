import './App.css';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import WorkDetail from './Pages/WorkDetail';
import { Contact } from './Pages/Contact';
import { Meet } from './Pages/Meet';
import LearnMore from './Pages/LearnMore';
import { Referal } from './Pages/Referal';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';


function App() {

  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 50,
      easing: "ease-out",
      offset: 200,
      mirror: true,
      anchorPlacement: "top-bottom",
      once: false,
    });
  }, []);

  useEffect(() => {
    Aos.refresh();
  }, []);


  return (
    <div className="m-0 p-0">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/learnmore' element={<LearnMore />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/meet' element={<Meet />} />
          <Route path='/earn-money' element={<Referal />} />
          <Route path={`/workdetail/:id`} element={<WorkDetail />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
