import React from 'react';
import { useNavigate } from 'react-router-dom';
import Media from '../Assests/Media';

const Works = () => {
    const navigate = useNavigate();

    const handleNavigate = (item) => {
        navigate(`/workdetail/${item.id}`, { state: item });
    }

    const workData = [
        {
            id: 1,
            thumbnail: Media.tax_2,
            heading: "Tax software",
            images: [
                Media.tax_2,
                Media.tax_1,
                Media.tax_3,
                Media.tax_4,
                Media.tax_5,
            ],
            youtube_link: "https://youtu.be/mrZeKrgS2vQ",
            review_heading: `"Tech is everything, and this guys are best"`,
            review_person: "Pintoo soni",
            review_brand: "Saraswati Medical",
            review_image: Media.PintooSoni,
            gradient: "purple_gradient",
        },
        {
            id: 2,
            thumbnail: Media.r1,
            heading: "rnvaastuconsultant.com",
            images: [
                Media.r1,
                Media.r2,
                Media.r3,
                Media.r4,
                Media.r5,
            ],
            youtube_link: "https://youtu.be/QTf2sB6FAwc",
            review_heading: `"I thought digitalizing is complicated but they made it look so easy"`,
            review_person: "Rajesh Nakar",
            review_brand: "rnvaastuconsultant.com",
            review_image: Media.RajeshNakar,
            gradient: "yellow_gradient",
        },
        {
            id: 3,
            thumbnail: Media.jain2,
            heading: "Quotation Generator Software",
            images: [
                Media.jain2,
                Media.jain1,
                Media.jain3,
                Media.jain4,
                Media.jain5,
            ],
            youtube_link: "https://youtu.be/jEumXl-tSh0",
            review_heading: `"He turned my hours of work in seconds"`,
            review_person: "Shripal Jain",
            review_brand: "JainToursAndTravels",
            review_image: Media.ShripalJain,
            gradient: "blue_gradient",
        },
        {
            id: 4,
            thumbnail: Media.lead2,
            heading: "Lead Generation Software",
            images: [
                Media.lead2,
                Media.lead1,
            ],
            youtube_link: "https://youtu.be/NkkDlimrYC4",
            review_heading: `"Bro removed pendrives from my life, now we make leads together"`,
            review_person: "Anonymous",
            review_brand: "private company",
            review_image: "https://images.pexels.com/photos/25677022/pexels-photo-25677022/free-photo-of-person-cleaning-square-near-temple-in-downpour.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            gradient: "pink_gradient",
        },
        {
            id: 5,
            thumbnail: Media.calc1,
            heading: "Bill making solution",
            images: [
                Media.calc1,
                Media.calc2,
            ],
            youtube_link: "https://youtu.be/NkkDlimrYC4",
            review_heading: `"Solved my bill making problem"`,
            review_person: "Rajesh Nakar (repeat client)",
            review_brand: "rnvaastuconsultant.com",
            review_image: Media.RajeshNakar,
            gradient: "purple_gradient",
        },
        {
            id: 6,
            thumbnail: Media.dev3,
            heading: "Website",
            images: [
                Media.dev1,
                Media.dev2,
                Media.dev3,
                Media.dev4,
                Media.dev5,
            ],
            youtube_link: "devstacks.in",
            review_heading: `"Best programmer to ever work with"`,
            review_person: "Jatin Mistry",
            review_brand: "devstacks.in",
            review_image: Media.JaitnMistry,
            gradient: "yellow_gradient",
        },
        {
            id: 7,
            thumbnail: Media.sujal1,
            heading: "Sujalarts.in",
            images: [
                Media.sujal1,
                Media.sujal2,
                Media.sujal3,
                Media.sujal4,

            ],
            youtube_link: "sujalarts.in",
            review_heading: `"He understood my motive properly"`,
            review_person: "Sujal Jain",
            review_brand: "sujalarts.in",
            review_image: Media.sujal1,
            gradient: "yellow_gradient",
        },
    ];

    return (
        <div id='latest_projects' className='flex flex-col justify-center items-start w-full h-fit'>
            <h6 className='company_name text-xl text-nowrap text-center w-1/5'>Works :</h6>
            <div className="flex flex-row flex-wrap justify-center items-center w-full h-fit gap-2 md:gap-5">
                {workData.slice().reverse().map((item, index) => (
                    <div
                        data-aos="fade-right"
                        key={index}
                        className='box_shadow h-fit w-11/12 bg-purple-500 flex justify-center items-center overflow-hidden md:w-5/12 cursor-pointer rounded-xl'
                        onClick={() => handleNavigate(item)}
                    >
                        <img className='img_hover h-fit scale-125' src={item.thumbnail} alt={item.heading} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Works;