import React from 'react';
import { useNavigate } from 'react-router-dom';

const Service = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/meet');
    };

    return (
        <>
            <div className="flex flex-col gap-36 w-full h-fit justify-center items-center">


                <h2 data-aos="fade-up" className="text-4xl font-semibold font-sans tracking-tight text-center mt-[150px] md:text-7xl">Apps, websites, <br />software, & more</h2>
                {/* SERVICE ANIMATIONS */}
                <div data-aos="fade-up" id='black_background' className="flex justify-center items-center w-full h-96 mt-[50px] mb-[50px] rounded-xl overflow-x-scroll">

                    <div className="container1">
                        <div data-text="Website" style={{ '--r': -15 }} className="glass">
                            <svg viewBox="0 0 24 24" fill="#FFFFFF" height="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 01-7.473-4.97A7.978 7.978 0 0012 20a7.978 7.978 0 007.473-4.97A8 8 0 0112 20zM12 4a7.978 7.978 0 00-7.473 4.97A7.978 7.978 0 0112 4zm0 16a7.978 7.978 0 01-7.473-4.97A8 8 0 0012 20a8 8 0 007.473-4.97A7.978 7.978 0 0112 20zM12 4a7.978 7.978 0 00-7.473 4.97A7.978 7.978 0 0112 4zm0 16a7.978 7.978 0 01-7.473-4.97A8 8 0 0012 20a8 8 0 007.473-4.97A7.978 7.978 0 0112 20zM12 4a7.978 7.978 0 00-7.473 4.97A7.978 7.978 0 0112 4zm0 16a7.978 7.978 0 01-7.473-4.97A8 8 0 0012 20a8 8 0 007.473-4.97A7.978 7.978 0 0112 20z" />
                            </svg>
                        </div>
                        <div data-text="Apps" style={{ '--r': 5 }} className="glass">
                            <svg viewBox="0 0 24 24" fill="#FFFFFF" height="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3h6v6H3V3zm8 0h10v6H11V3zm-8 8h6v10H3V11zm8 0h10v10H11V11z" />
                            </svg>
                        </div>
                        <div data-text="Software" style={{ '--r': 25 }} className="glass">
                            <svg viewBox="0 0 24 24" fill="#FFFFFF" height="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 20c0 1.1.89 2 1.99 2H20c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM10 17H8v-2h2v2zm0-4H8V7h2v6zm6 4h-2v-4h2v4zm0-6h-2V7h2v4z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <button data-aos="fade-up" onClick={handleNavigate} className="call_button">
                    <span>Book a call</span>
                </button>
            </div>
        </>
    );
};

export default Service;
