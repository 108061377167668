import smile from '../Assests/smile.png';
import tax_1 from '../Assests/tax1 (1).png'
import tax_2 from '../Assests/tax1 (2).png'
import tax_3 from '../Assests/tax1 (3).png'
import tax_4 from '../Assests/tax1 (4).png'
import tax_5 from '../Assests/tax1 (5).png'
import r1 from '../Assests/rnvaastu (1).png'
import r2 from '../Assests/rnvaastu (2).png'
import r3 from '../Assests/rnvaastu (3).png'
import r4 from '../Assests/rnvaastu (4).png'
import r5 from '../Assests/rnvaastu (5).png'
import lead1 from '../Assests/lead (1).png'
import lead2 from '../Assests/lead (2).png'
import jain1 from '../Assests/jain (1).png'
import jain2 from '../Assests/jain (2).png'
import jain3 from '../Assests/jain (3).png'
import jain4 from '../Assests/jain (4).png'
import jain5 from '../Assests/jain (5).png'
import dev1 from '../Assests/devstack (1).png'
import dev2 from '../Assests/devstack (2).png'
import dev3 from '../Assests/devstack (3).png'
import dev4 from '../Assests/devstack (4).png'
import dev5 from '../Assests/devstack (5).png'
import calc1 from '../Assests/calc (1).png'
import calc2 from '../Assests/calc (2).png'
import RajeshNakar from '../Assests/Rajeshnakar.jpg'
import PintooSoni from '../Assests/Pintoosoni.jpg'
import ShripalJain from '../Assests/Shripaljain.png'
import JaitnMistry from '../Assests/Jatinmistry.png'
import face from '../Assests/face.webp';
import sujal1 from '../Assests/sujal.png';
import sujal2 from '../Assests/sujal (2).png';
import sujal3 from '../Assests/sujal (3).png';
import sujal4 from '../Assests/sujal (4).png';
export default {
    smile,
    tax_1,
    tax_2,
    tax_3,
    tax_4,
    tax_5,
    r1,
    r2,
    r3,
    r4,
    r5,
    lead1,
    lead2,
    jain1,
    jain2,
    jain3,
    jain4,
    jain5,
    dev1,
    dev2,
    dev3,
    dev4,
    dev5,
    calc1,
    calc2,
    RajeshNakar,
    PintooSoni,
    ShripalJain,
    JaitnMistry,
    face,
    sujal1,
    sujal2,
    sujal3,
    sujal4,

}