import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Question = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const navigate = useNavigate();

    const questions = [
        {
            question: "What services does your tech agency offer?",
            answer: "Our tech agency offers a wide range of services including website development, mobile app development, custom software solutions, system integration, UX/UI design, and ongoing support and maintenance."
        },
        {
            question: "How can your agency help my business with website development?",
            answer: "We can help your business with website development by creating a custom, responsive, and user-friendly website tailored to your specific needs. Our team ensures that your website is optimized for performance, SEO-friendly, and designed to provide a seamless user experience."
        },
        {
            question: "What platforms do you develop mobile apps for?",
            answer: "We develop mobile apps for both iOS and Android platforms, ensuring your app reaches the widest possible audience with high performance and great user experience."
        },
        {
            question: "Can your agency create custom software solutions for specific business needs?",
            answer: "Yes, our agency specializes in creating custom software solutions tailored to meet the specific needs of your business, enhancing efficiency, productivity, and overall performance."
        },
        {
            question: "How do you ensure security and reliability in your software solutions?",
            answer: "We ensure security and reliability by following industry best practices, conducting thorough testing, implementing robust security measures, and using reliable technologies. Our team stays updated with the latest security trends to protect your data and systems."
        },
        {
            question: "What support and maintenance services do you offer after the project is completed?",
            answer: "After the project is completed, we offer comprehensive support and maintenance services including bug fixes, updates, performance monitoring, and enhancements to ensure your solution remains up-to-date and operates smoothly."
        },
        {
            question: "How do you handle scalability in your solutions?",
            answer: "We design our solutions with scalability in mind, allowing your system to grow and adapt as your business expands. We use scalable architecture and cloud technologies to ensure your solution can handle increased demand seamlessly."
        },
        {
            question: "Can you integrate our existing systems with new software or applications?",
            answer: "Yes, we can integrate your existing systems with new software or applications, ensuring a smooth transition and seamless operation. Our team has experience in system integration and data migration to minimize disruption to your business."
        },
        {
            question: "What is your approach to user experience and interface design?",
            answer: "Our approach to user experience and interface design is user-centered. We focus on creating intuitive, engaging, and accessible designs that provide a positive experience for your users, enhancing satisfaction and usability."
        },
        {
            question: "How do I get started with your services?",
            answer: "Getting started is easy. Simply contact us to discuss your project requirements and goals. We'll provide a personalized consultation and proposal outlining how we can best meet your technology needs. From there, we'll work closely with you through every step of the development process to deliver a solution that exceeds your expectations."
        }
    ];

    const handleQuestionClick = (index) => {
        setSelectedQuestion(selectedQuestion === index ? null : index);
    };

    return (
        // main container 
        <div className='flex flex-row flex-wrap lg:flex-nowrap justify-center items-center gap-8 w-full h-fit'>

            {/* banner container  */}
            <div className='flex flex-col justify-center items-start w-4/5 md:w-5/12 h-fit box_shadow bg-black gap-6 rounded-lg'>

                {/* slots available  */}
                <div className="flex flex-row justify-center items-center w-36 h-11 bg-pink-400 rounded-full mt-[51px] ml-[10px] lg:ml-[51px] gap-2">
                    <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                    <p className='text-xs font-bold text-nowrap text-center font-sans'>Slots available</p>
                </div>

                {/* heading  */}
                <h2 className='text-6xl sm:text-8xl text-white font-sans font-semibold tracking-tight text-left ml-[10px] lg:ml-[51px] '>Join <br />TechJoy</h2>

                {/* 15 min call conatiner  */}
                <div onClick={() => navigate('/meet')} className="border_2px_solid_white flex flex-row w-11/12 ;g:w-4/5 h-fit justify-between items-center rounded-xl mt-[148px] mb-[127px] m-auto cursor-pointer">

                    {/* text container  */}
                    <div className='flex flex-col justify-center items-start gap-4'>

                        {/* heading  */}
                        <h2 className='mt-[17px] ml-[10px] lg:ml-[17px] text-xs lg:text-2xl font-normal font-sans text-nowrap text-left tracking-tight text-white'>Book a 15-min intro call</h2>
                        {/* text  */}
                        <p className=' ml-[10px] lg:ml-[17px] mb-[17px] text-sm lg:text-xl text-white font-light text-left font-sans tracking-light'>Learn more about how TechJoy works and how it can help you.</p>
                    </div>

                    {/* arrow svg  */}
                    <div className='w-1/5'>
                        <img className='w-6 h-6 mr-[17px]' src="https://img.icons8.com/?size=100&id=gkgXdvj3Owk3&format=png&color=000000" alt="techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions, techjoy, tech agency, web development, app development, custom software, digital solutions, tech services, IT consulting, software solutions, mobile apps, website design, e-commerce solutions, cloud computing, UI/UX design, software development, digital transformation, technology solutions, software engineering, cybersecurity services, AI development, machine learning solutions" />
                    </div>
                </div>


            </div>
            {/* question answer div  */}
            <div>
                {/* Heading of questin answers 
                 */}
                <h2 className='text-6xl font-sans text-left font-semibold text-black text-wrap tracking-tighter'>Commonly asked questions</h2>

                {/* list of questions  */}
                <ul className='list-none mt-[36px]'>
                    {questions.map((item, index) => (
                        <li key={index} className='mb-4'>
                            <div
                                className='text-base font-sans font-semibold tracking-normal cursor-pointer'
                                onClick={() => handleQuestionClick(index)}
                            >
                                {item.question}
                            </div>
                            {selectedQuestion === index && (
                                <p className='text-base font-sans tracking-normal mt-2'>
                                    {item.answer}
                                </p>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Question
