import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
    const handleInfo = (link) => {
        navigate(`/${link}`)
    }
    return (
        <div className='flex flex-col justify-center items-center gap-9 font-semibold mt-[78px] w-full h-fit text-xs md:text-xl'>
            <div className="flex flex-row justify-between items-center w-4/5">
                <a href='#latest_projects'>Latest Projects</a>
                <a href='#pricing'>Pricing</a>
                <a href='#get_started'>Get started</a>
            </div>
            <div className="flex flex-row justify-between items-center w-4/5">
                <p className='hover:text-blue-600 cursor-pointer' onClick={() => handleInfo("meet")}>Contact</p>
                <p className='hover:text-blue-600 cursor-pointer' onClick={() => handleInfo("earn-money")}>Earn Money</p>
                <p className='hover:text-blue-600 cursor-pointer' onClick={() => handleInfo("learnmore")}>Terms & conditions</p>
            </div>
            <div className="company_name flex flex-row flex-wrap justify-between items-center w-4/5 font-bold mt-[115px] mb-[150px]">
                <p onClick={() => window.location.href = `https://mail.google.com/mail/?view=cm&fs=1&to=lalitsoni.business@gmail.com`} className='cursor-pointer hover:text-blue-700'>lalitsoni.business@gmail.com</p>
                <p onClick={() => window.location.href = `tel:+919860852466`} className='cursor-pointer hover:text-blue-700'>9860852466</p>
                <p onClick={() => window.location.href = `https://instagram.com/TechJoy.in_/`} className='cursor-pointer hover:text-blue-700'>@TechJoy</p>
            </div>
        </div>
    )
}

export default Footer
