import React from 'react';
import { useLocation } from 'react-router-dom';
import BigHeading from '../Components/BigHeading';
import SingleReview from '../Components/SingleReview';
import Right from '../Components/Right';
import Media from '../Assests/Media';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

const WorkDetail = () => {
  const location = useLocation();
  const workItem = location.state;

  return (
    <>
      <Helmet>
        <title>{`${workItem.heading} | TechJoy Project Case Study`}</title>
        <meta name="description" content={`Explore TechJoy's ${workItem.heading} project. See how we delivered innovative solutions for ${workItem.review_brand}. View images, video, and client testimonial.`} />
        <meta name="keywords" content={`${workItem.heading}, TechJoy, case study, ${workItem.review_brand}, web development, app development, custom software`} />
        <link rel="canonical" href={`https://techjoy.in/work/${workItem.heading.toLowerCase().replace(/ /g, '-')}`} />

        <meta property="og:title" content={`${workItem.heading} | TechJoy Project Case Study`} />
        <meta property="og:description" content={`Discover how TechJoy delivered ${workItem.heading} for ${workItem.review_brand}. View project details and client feedback.`} />
        <meta property="og:url" content={`https://techjoy.in/workdetail/${workItem.id}`} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={workItem.images[0]} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${workItem.heading} | TechJoy Project Case Study`} />
        <meta name="twitter:description" content={`Discover how TechJoy delivered ${workItem.heading} for ${workItem.review_brand}. View project details and client feedback.`} />
        <meta name="twitter:image" content={workItem.images[0]} />

        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": "${workItem.heading} - TechJoy Project Case Study",
          "image": ${JSON.stringify(workItem.images)},
          "author": {
            "@type": "Organization",
            "name": "TechJoy"
          },
          "publisher": {
            "@type": "Organization",
            "name": "TechJoy",
            "logo": {
              "@type": "ImageObject",
              "url": "https://techjoy.in/public/favicon.ico"
            }
          },
          "datePublished": "${new Date().toISOString()}",
          "dateModified": "${new Date().toISOString()}",
          "description": "Explore TechJoy's ${workItem.heading} project. See how we delivered innovative solutions for ${workItem.review_brand}.",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://techjoy.in/work/${workItem.heading.toLowerCase().replace(/ /g, '-')}"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "${workItem.review_person}"
            },
            "reviewBody": "${workItem.review_heading}"
          }
        }
      `}
        </script>
      </Helmet>
      <div className='flex flex-col justify-center items-center w-full h-fit mb-10'>
        <div id="background_image" className='flex flex-col justify-center items-center w-11/12 h-fit overflow-hidden'>
          <h1 className='text-5xl font-semibold text-center tracking-tight font-sans mt-[150px]'>{workItem.heading}</h1>
          <div className="flex flex-row flex-wrap w-4/5 h-fit gap-3 justify-center items-center mt-[150px]">
            {workItem.images.map((image, index) => (
              <img key={index} className='drop_shadow w-full md:w-2/5 h-fit rounded-xl' src={image} alt={`${workItem.heading} ${index}`} />
            ))}
          </div>
          <div onClick={() => window.location.href = `${workItem.youtube_link}`} className='cursor-pointer text-purple-600 hover:text-blue-600'>
            <BigHeading heading="Watch video description" />
          </div>
          <div className="flex flex-row justify-center items-center gap-4 flex-wrap w-full h-fit md:w-3/4">
            <SingleReview
              class={workItem.gradient}
              compliment={workItem.review_heading}
              person={workItem.review_person}
              brand={workItem.review_brand}
              image={workItem.review_image}
            />
          </div>
          <Right />
          <BigHeading image={Media.smile} heading="TechJoy" />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default WorkDetail;