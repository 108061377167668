import React from 'react'

const SingleReview = (props) => {
    return (
        <div data-aos="fade-up" className={`width_498px ${props.class} border-solid gap-8 flex flex-col justify-center items-center rounded-xl`} >

            <h6 className='sm:text-3xl text-xl text-wrap font-sans font-semibold tracking-tight w-4/5'>{props.compliment}</h6>
            <div className="flex flex-row flex-nowrap justify-center items-end md:gap-0 gap-2">
                <p className='sm:text-sm text-xs font-semibold w-5/12 font-sans text-left'>{props.person}<br />{props.brand}</p>
                <img className='w-5/12 h-fit rounded-xl' src={props.image} alt={`${props.compliment}, Thankyou techjoy by ${props.brand} owned by ${props.person}`} />
            </div>
        </div >
    )
}

export default SingleReview
