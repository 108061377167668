import React from 'react';
import { useNavigate } from 'react-router-dom';
import Media from '../Assests/Media';


const IntroCall = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/meet')
    }
    return (
        // main container 
        <div data-aos="fade-right" id='get_started' className='box_shadow flex flex-col justify-center items-center gap-6 w-4/5 md:w-96 h-fit m-auto bg-white rounded-lg mt-[150px] mb-[150px]'>
            {/* image container  */}
            <div data-aos="fade-down" className="w-36 h-36 rounded-full flex justify-center items-center overflow-hidden mt-[60px]">
                <img className='scale-150' src={Media.face} alt="intro call techjoy schedule consultation book now 15-minute discussion tech services online meeting appointment" />
            </div>
            {/* heading  */}
            <h6 data-aos="fade-right" className='text-2xl md:text-4xl font-sans font-semibold tracking-normal text-nowrap text-center w-full'>Book a 15-minute <br /> intro call</h6>
            {/* Shedule button  */}
            {/* <button className='w-28 h-11 bg-slate-800 rounded-xl text-white  font-sans font-medium text-xs md:w-48 md:h-14 md:text-xl'>Shedule now</button> */}
            <a data-aos="fade-right" onClick={handleNavigate} className="fancy" href="#">
                <span className="top-key"></span>
                <span className="text">Schedule Now</span>
                <span className="bottom-key-1"></span>
                <span className="bottom-key-2"></span>
            </a>
            {/* last line  */}
            <p data-aos="fade-up" onClick={() => window.location.href = `https://mail.google.com/mail/?view=cm&fs=1&to=lalitsoni.business@gmail.com`} className='text-center text-xs font-sans text-black font-semibold tracking-normal text-wrap md:text-nowrap mb-[60px]'>Prefer to email? <span className='text-red-600 cursor-pointer'>lalitsoni.business@gmail.com</span></p>
        </div>
    )
}

export default IntroCall
