import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

export const Referal = () => {
    const [formData, setFormData] = useState({
        help_name: '',
        help_number: '',
        help_email: '',
        help_upi: '',
        client_name: '',
        client_number: '',
        client_email: '',
        client_work: '',
        client_description: '',
    });

    const sendEmail = (e) => {
        e.preventDefault();

        const templateParams = {
            help_name: formData.help_name,
            help_number: formData.help_number,
            help_email: formData.help_email,
            help_upi: formData.help_upi,
            client_name: formData.client_name,
            client_number: formData.client_number,
            client_email: formData.client_email,
            client_work: formData.client_work,
            client_description: formData.client_description,
        };

        emailjs
            .send('service_yp2clzl', 'template_p6ilgjc', templateParams, 'vMMuU8KueyoZ85f3r')
            .then(
                () => {
                    alert("Message Sent Successfully");
                    alert("You will get a reply within an hour");
                    console.log('SUCCESS!');
                    // Reset form data after successful submission
                    setFormData({
                        help_name: '',
                        help_number: '',
                        help_email: '',
                        help_upi: '',
                        client_name: '',
                        client_number: '',
                        client_email: '',
                        client_work: '',
                        client_description: '',
                    });
                },
                (error) => {
                    alert("Failed to send message");
                    console.log('FAILED...', error);
                },
            );
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Helmet>
                <title>Earn Money with TechJoy | Refer Clients & Get Up to ₹5000 Reward</title>
                <meta name="description" content="Earn easy money from ₹1000 up to ₹5000 by referring clients to TechJoy for web, app, and software development services. Get rewarded for successful referrals without any investment." />
                <meta name="keywords" content="earn money online, TechJoy referral program, easy money, client referrals, web development, app development, software development, referral reward" />
                <link rel="canonical" href="https://techjoy.in/earn-money" />
                <meta property="og:title" content="Earn Money with TechJoy | Refer Clients & Get Up to ₹5000 Reward" />
                <meta property="og:description" content="Make easy money from ₹1000 to ₹5000 by referring clients to TechJoy for tech services. No investment needed, get rewarded for successful referrals!" />
                <meta property="og:url" content="https://techjoy.in/earn-money" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://techjoy.in/public/favicon.ico" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Earn Money with TechJoy | Refer Clients & Get Up to ₹5000 Reward" />
                <meta name="twitter:description" content="Make easy money from ₹1000 to ₹5000 by referring clients to TechJoy for tech services. No investment needed, get rewarded for successful referrals!" />
                <meta name="twitter:image" content="https://techjoy.in/public/favicon.ico" />
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Earn Money with TechJoy Referral Program",
          "description": "Earn easy money from ₹1000 up to ₹5000 by referring clients to TechJoy for web, app, and software development services.",
          "url": "https://techjoy.in/earn-money",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://techjoy.in/earn-money"
          },
          "offers": {
            "@type": "Offer",
            "description": "Earn from ₹1000 to ₹5000 for successful client referrals",
            "priceCurrency": "INR",
            "lowPrice": "1000",
            "highPrice": "5000"
          },
          "potentialAction": {
            "@type": "Action",
            "name": "Refer a Client",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://techjoy.in/earn-money#referral-form",
              "actionPlatform": [
                "http://schema.org/DesktopWebPlatform",
                "http://schema.org/MobileWebPlatform"
              ]
            }
          }
        }
      `}
                </script>
            </Helmet>
            <div className="bg-gray-100 py-12">
                <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-8">
                    <h2 className="text-2xl font-bold mb-6">Make easy money</h2>
                    <h2 className="text-2xl font-bold mb-6">From</h2>
                    <h2 className="text-2xl text-green-600 font-bold mb-6">₹1000, ₹2000 upto ₹5000</h2>
                    <p className=''>Just refer me someone else who may want my services just like you and earn minimum 1k and maximum to 5k in referal gift .Without letting them know about the referal gift.</p>
                    <br />
                    <p className=''>You will receive the gift as soon as the advance payment is done by the client ,after a successful deal.</p>
                    <br />
                    <p className=''>You must be wondering how will you know that the deal has been made or not ,see guys this is my portfolio website, any work or project that I will do will be uploaded here , so there is no chance of you getting scammed by this. </p>
                    <br />
                    <p className=''>Not forcing ,the choice is yours .If I would have been at your place I would not miss this opportunity.</p>
                    <br />
                    <br />
                    <br />
                    <form id='referral-form' onSubmit={sendEmail} className="space-y-4">
                        <div>
                            <label className="block font-medium">Your Name:</label>
                            <input
                                type="text"
                                name="help_name"
                                required
                                placeholder='e.g. Akash Gupta'
                                value={formData.help_name}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Your Phone Number:</label>
                            <input
                                type="tel"
                                name="help_number"
                                required
                                placeholder='e.g. 9898989898'
                                value={formData.help_number}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Your Email:</label>
                            <input
                                type="email"
                                name="help_email"
                                required
                                placeholder='e.g. akash@gmail.com'
                                value={formData.help_email}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Your UPI ID:</label>
                            <input
                                type="text"
                                name="help_upi"
                                required
                                placeholder='e.g. 9898989898@axl'
                                value={formData.help_upi}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>

                        <h1 className="text-xl font-bold mt-8 mb-4">
                            Now write the details of the person you want to refer
                        </h1>

                        <div>
                            <label className="block font-medium">Client Name:</label>
                            <input
                                type="text"
                                name="client_name"
                                required
                                placeholder='e.g. Akash Gupta'
                                value={formData.client_name}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Client Number:</label>
                            <input
                                type="tel"
                                name="client_number"
                                required
                                placeholder='e.g. 9898989898'
                                value={formData.client_number}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Client Email:</label>
                            <input
                                type="email"
                                name="client_email"
                                required
                                placeholder='e.g. akash@gmail.com'
                                value={formData.client_email}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Client's Profession:</label>
                            <input
                                type="text"
                                name="client_work"
                                required
                                placeholder='e.g. Doctor'
                                value={formData.client_work}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block font-medium">Client's Project Description:</label>
                            <textarea
                                name="client_description"
                                required
                                placeholder='Write what they need, e.g. Website/Software/App, and describe it'
                                value={formData.client_description}
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-indigo-600 text-white rounded-md py-2 hover:bg-indigo-700 transition-colors duration-300"
                        >
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </>

    );
};