import React from 'react'
import SingleReview from './SingleReview'
import Media from '../Assests/Media'

const Review = () => {
    return (
        <div className='flex flex-col justify-center items-center w-full h-fit mt-[150px]'>
            <h6 className='company_name text-xl text-nowrap text-left  md:w-1/5 mb-3'>Customer satisfaction : </h6>
            <div className="flex flex-row justify-center items-center gap-4 flex-wrap w-full h-fit md:w-3/4 ">
                <SingleReview class="purple_gradient" compliment={`"Tech is everything , and this guys are best"`} person="Pintoo Soni" brand="Saraswati Medical" image={Media.PintooSoni} />
                <SingleReview class="yellow_gradient" compliment={`"I thought digitalizing is complicated but they made it look so easy"`} person="Rajesh Nakar" brand="rnvaastuconsultant.com" image={Media.RajeshNakar} />
                <SingleReview class="blue_gradient" compliment={`"He turned my hours of work in seconds"`} person="Shripal Jain" brand="jainToursAndTravels" image={Media.ShripalJain} />
                <SingleReview class="pink_gradient" compliment={`"Best programmer to ever work with"`} person="Jatin Mistry" brand="devstacks.in" image={Media.JaitnMistry} />
            </div>
        </div>
    )
}

export default Review
